import { Container } from "react-bootstrap";

function Rules() {
    return (
      <>
      <Container className="content">
          <h1 style={{paddingTop:'50px'}}>RULES</h1>
          <p>Ahead of the run please familiarize yourself with the following rules and adhere to them at all times during the event.</p>
          <ul>
            <li>The full distance must be completed on foot, keeping, wherever possible, to the recognised towpath route and in single file unless overtaking</li>
            <li>All Competitors must notify Race HQ (Tel. 07830085132 or 07780685538) immediately on retiring (even if they have already reported this at a checkpoint) or if they drop behind a schedule in line with the cut-off times.</li>
            <li>Competitors must reach all checkpoints by the closing times printed on the supplied maps. They must leave within 40 minutes of arrival.</li>
            <li>Competitors will be deemed to have retired if stopped for more than 40 minutes at any one time. Competitors may enter vehicles for warmth, rest and nourishment, but will be disqualified if the vehicle moves.</li>
            <li>Any runner seen in a moving vehicle will count as retired, even if the intention is to return to the same place to resume, unless that vehicle is going to the aid of another entrant in difficulty.</li>
            <li>All competitors must display their race number to the front. It will be recorded by race volunteers at all checkpoints and throughout the race.</li>
            <li>The medical form on the reverse of the number must be completed including emergency contact name and telephone number.</li>
            <li>Supported (with own crew) competitors must have at least one support crew member with a vehicle from the Start, and for the duration of the race. The crew must also have the means to communicate with race HQ and it is the responsibility of the runner to provide crews with all the relevant information.</li>
            <li>All competitors must carry a mobile phone, switched on at all times, for calls to or from Race HQ; and have the financial means to sustain themselves in the event of breakdown of support vehicle.</li>
            <li>All competitors must carry a foil ‘space’ blanket at all times.</li>
            <li>At night, all competitors and buddies must carry a torch or headlamp, with spare batteries, and wear reflective clothing when on road sections.</li>
            <li>All competitors must bring their own cup for accessing hot and cold drinks at aid stations.</li>
            <li>Unsupported competitors must carry the provided maps, at all times, in order to pin-point their location should our transport need to collect them upon retirement.</li>
            <li>Unsupported competitors must have one kit bag only, clearly labelled. Competitors must minimise the size and weight of their bag, bringing only what is necessary.</li>
            <li>The event team will use the App 'What 3 words' to ensure accuracy of checkpoints and locating runners and it is advised that all runners use the same process.</li>
            <li>Buddy runners (someone who runs with the competitor) must be on foot. They must not run in front of their runner, always behind. If in front they will be regarded as pacers and pacing is not permitted in this event.</li>
            <li>Competitors may have only one buddy runner at a time from Checkpoint 3 onwards.</li>
            <li>Buddy runners must not carry any food, water or other supplies for their runner. Buddies must checkpoints at the same time as their runner.</li>
            <li>Sharps, any needles, scalpels, or other articles that could cause wounds or punctures to personnel handling them, must be locked within a suitable container inside kit-bags.</li>
            <li>Competitors, crews and buddies must accept full responsibility for their actions and act in a socially responsible way at all times.</li>
            <li>If unable to take part, entrants must undertake to inform the organiser – before race day please.</li>  
          </ul>
      </Container>
      </>
    );

  }
  
  export {Rules};