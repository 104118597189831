import Table from 'react-bootstrap/Table';

function RouteTable() {
  return (
    <>
    <div className="padding-spacing">
    <Table striped bordered hover>
      <thead class>
        <tr>
          <th>Distance</th>
          <th>Checkpoint</th>
          <th>Location</th>
          <th>Postcode</th>
          <th>What3Words</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>0M
            <br></br>
            0KM
            </td>
          <td>START</td>
          <td>New Era Community Boxing</td>
          <td>CW9 5JN</td>
          <td>///clouds.lakes.valley</td>
        </tr>
        <tr>
          <td>19M 
            <br></br>
            30.5KM</td>
          <td>CP1</td>
          <td>Off Mitchell Street, Stockton Heath</td>
          <td>WA4 6LS</td>
          <td>///trails.trying.chase</td>
        </tr>
        <tr>
          <td>35.6M<br></br>57.3KM</td>
          <td>CP2</td>
          <td>Sir Matt Busby Way, Manchester</td>
          <td>M16 0RA</td>
          <td>///theme.races.loves</td>
        </tr>
        <tr>
          <td>53M<br></br>85.3KM</td>
          <td>CP3</td>
          <td>Marple Locks, Brabyns Park, Marple.</td>
          <td>SK6 5DT</td>
          <td>///meal.confused.breathing</td>
        </tr>
        <tr>
          <td>71.2M<br></br>114.5KM</td>
          <td>CP4</td>
          <td>Bosley locks, Bullgate Ln, Congleton</td>
          <td>CW12 2PB</td>
          <td>///discouraged.snacking.runways</td>
        </tr>
        <tr>
          <td>88.2M<br></br>142KM</td>
          <td>CP5</td>
          <td>Cheshire Cheese Inn, Off Crewe road, Wheelock</td>
          <td>CW11 3RL</td>
          <td>///aims.lunch.swoop</td>
        </tr>
        <tr>
          <td>100M<br></br>160.9KM</td>
          <td>FINISH</td>
          <td>New Era Community Boxing</td>
          <td>CW9 5JN</td>
          <td>///clouds.lakes.valley</td>
        </tr>
      </tbody>
    </Table>
    </div>
    </>
  );
}

export default RouteTable;