import React from "react";
import { Container } from "react-bootstrap";
import useEventbrite from "react-eventbrite-popup-checkout";

const Eventbrite = () => {
  const handleOrderCompleted = React.useCallback(() => {
    console.log("Order was completed successfully");
  }, []);
  const iframeCheckout = useEventbrite({
    eventId: "797260085377",
    modal: false,
    onOrderComplete: handleOrderCompleted,
    iFrameHeight: 500, // optional
    iFrameAutoAdapt: 100, // optional - The widget's viewport percentage (between 75-100)
  });

  return (
    <Container>
      <div className="padding-spacing"></div>
      <div id="my-app">
        {/* guard for null - resolves when Eventbrite loads */}
        {iframeCheckout && <div id={iframeCheckout.id} />}
      </div>
    </Container>
  );
};

export { Eventbrite };
