import { Container } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";

function Contact() {
    return (
      <>
      <Container className="content">
      <h1 style={{paddingTop:'50px'}}>CONTACT US</h1>
      <p>
        If you were unable to find an answer to your question on our website, please feel free to get in touch. A member of the team will be in touch to answer your query.
      </p>

      <Form action="https://formsubmit.co/neweraboxing11@gmail.com" method="POST">
      {/* Honeypot */}
      <Form.Group className="mb-3" style={{display:"none"}}>
        <Form.Label>Your name</Form.Label>
        <Form.Control type="text" placeholder="Enter your name" />
        {/* <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text> */}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Your name</Form.Label>
        <Form.Control type="name" name="Name" placeholder="Enter your name" />
        {/* <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text> */}
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" name="Email" placeholder="Email" />
      </Form.Group>

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Your query</Form.Label>
        <Form.Control as="textarea" name="Comment" rows={5} />
      </Form.Group>

      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
      </Container>
      </>
    );
    
  }
  
  export {Contact};