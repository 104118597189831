import { Container } from "react-bootstrap";

function RouteMap() {
    return (
      <>
      <Container>
        <div className="map-container padding-spacing">
          <div className="map-div">
          <iframe name="plotaroute_map_1984381" src="https://www.plotaroute.com/embedmap/1984381?units=miles" className="map-iframe" frameborder="0" scrolling="no" allowfullscreen webkitallowfullscreen       mozallowfullscreen oallowfullscreen msallowfullscreen>
          </iframe>
          </div>
        </div>
      </Container>
      </>
    );

  }
  
  export {RouteMap};