import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { HeroImage } from './HeroImage';
import HomeCards from './HomeCards';
import { Eventbrite } from './Eventbrite';
import { RouteMap } from './RouteMap';

function Home() {
  return (
    <>
    <Container className='content'>
    <HeroImage />
    <HomeCards />
    <RouteMap />
    <Eventbrite />
    </Container>
    </>
  );
  
}

export {Home};