import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../images/The Cheshire 100 Ultra Race.svg'

function Navigation() {
  return (
    <>
    <Navbar className='navbar' expand="sm">
      <Container className='header-container'>
      <Container style={{padding:"0px"}}><a href='/'><img className='logo' src={Logo}></img></a></Container>

        {/* <Navbar.Brand href="/"><img className='logo' src={Logo}></img></Navbar.Brand> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className='justify-content-end' id="basic-navbar-nav">
          <Nav>
            <Nav.Link href="/">HOME</Nav.Link>
            {/* <Nav.Link href="about">About</Nav.Link> */}
            <Nav.Link href="contact">CONTACT</Nav.Link>
            <Nav.Link href="route-information">ROUTE INFO</Nav.Link>
            <Nav.Link href="pre-race-notes">PRE-RACE NOTES</Nav.Link>
            <Nav.Link href="rules">RULES</Nav.Link>
            <Nav.Link href="terms-conditions">TERMS & CONDITIONS</Nav.Link>
            <Nav.Link href="how-to-enter" style={{paddingRight:"4px", paddingLeft:"4px"}} className='text-border'>HOW TO ENTER</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export { Navigation };