import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Footer } from './Components/Footer';
import { Home } from './Components/Home';
import { Route, Routes } from "react-router-dom"; 
import { Navigation } from './Components/Navigation';
import { PreRaceNotes } from './Components/PreRaceNotes';
import { TermsCond } from './Components/TermsCond';
import { Contact } from './Components/Contact';
import { RouteInformation } from './Components/RouteInformation';
import { Rules } from './Components/Rules';
import { Entry } from './Components/Entry';
import ScrollButton from './Components/ScrollButton';
import { useState, useEffect } from 'react';

function App() {
  ReactDOM.render(
    <BrowserRouter>
        {/* <div ref={refScrollUp}> </div> */}
        <Navigation />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/route-information" element={<RouteInformation />} />
            <Route path="/pre-race-notes" element={<PreRaceNotes />} />
            <Route path="/terms-conditions" element={<TermsCond />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/rules" element={<Rules />} />
            <Route path="/how-to-enter" element={<Entry />} />
          </Routes>
        <ScrollButton />
        <Footer />  
    </BrowserRouter>,
    document.getElementById('root'),
  );
}

// ReactDOM.render(
//   <BrowserRouter>
//       <Navigation />
//       <Routes>
// 					<Route path="/" element={<Home />} />
// 				</Routes>
//       <Footer />  
//   </BrowserRouter>,
//   document.getElementById('root'),
// );

export { App };