import { Container } from "react-bootstrap";
import RouteTable from "./RouteTable";
import { RouteMap } from "./RouteMap";
function RouteInformation() {
  return (
    <>
      <Container className="content">
        <h1 className="padding-spacing">ROUTE INFORMATION</h1>
        <h3 className="padding-spacing">REGISTRATION, START AND FINISH</h3>
        <p>
          Early Registration is open on Friday 2nd May is open from 16:00 till
          20:00 at New Era Community Boxing, CW9 5JN (What 3 words –
          Clouds.Lakes.Valley)
        </p>
        <p>
          Free car parking is available on site throughout and information will
          be available regarding the route and any questions can be directed
          towards race organisers.
        </p>
        <p>
          The venue will be used throughout as race HQ and support teams can use
          the facility throughout the duration of the race.
        </p>
        <p>The finish is at the same location of New Era Community Boxing.</p>
        <p>
          The initial start is 1.5 miles to the Trent and Mersey canal off
          Middlewich Road at the Old Broken Cross, CW9 7EB (What 3 words
          ///proud.banks.dreams).
        </p>
        <p>
          There is a car park (Limited) for support teams at the Old Broken
          Cross Inn. Once entering the Trent & Mersey Canal, runners should head
          NORTHBOUND in a clockwise direction heading towards PRESTON BROOK
          before reaching the BRIDGEWATER CANAL.
        </p>
        <p>
          The route will continue covering the ROCHDALE, ASHTON, PEAK FOREST,
          and MACCLESFIELD before returning to the TRENT AND MERSEY canal near
          RED BULL JUNCTION.
        </p>
        <p>
          The route and checkpoints are listed below and a GPX file will be made
          available for all runners and support teams.
        </p>
        <p>
          Care must be given to junctions whereby the canals split into
          different directions. Bridge closures and diversions will be made
          public ahead of time and if required, support staff will be in place
          to direct runners.
        </p>
        <p>
          It is the responsibility of runners and support staff to remain safe
          whilst on route. Where possible, any diversions will be directed to
          the shortest and safest route possible and runners must follow the
          route guidance in place.
        </p>

        <h3 className="padding-spacing">ROUTE MAP</h3>
        <p>
          PLEASE BE AWARE - This route map may be subject to slight changes
          ahead of the event and will be updated if so.
        </p>

        <RouteMap />
        <RouteTable />
      </Container>
    </>
  );
}

export { RouteInformation };
